body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-title {
  font-size: 2.5rem;
  color: #707f3c;
  font-weight: 700;
}

.news-feed {
  min-height: 75%;
  position: relative;
}

.news-feed-pagination {
  position: absolute;
  bottom: 0;
}

.news-feed-container-parent {
  overflow: hidden;
  width: 380px;
}

.news-feed-container {
  overflow-y: scroll;
  /* overflow: hidden; */
  padding-right: 100px;
}

.news-feed-container .news-item:hover {
  box-shadow: 0 0 3px 0px #00000030;
}

.news-item {
  padding: 10px;
  min-height: 100px;
  min-width: 380px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.news-item img,
.modal-body img {
  max-width: 100%;
}

.event-component-container-parent {
  overflow: hidden;
  height: 300px;
}

.event-component-container {
  overflow-x: scroll;
  padding-bottom: 20px;
}

.event-component-content {
  display: flex;
  transition: transform 0.1s ease;

}

.event-item {
  min-width: 300px;
  height: 250px;
  background-color: #f5f5f5;
  margin: 0px 10px;
}


main {
  min-height: 450px;
}

@page {
  size: auto;
  size: A4;
  margin: 5mm -32mm;
}