.bg-p1 {
  background-color: #2c333d;
}
.bg-p2 {
  background-color: #707f3c;
}
.app-carousel {
  border-radius: 10px;
  overflow: hidden;
}
.small-info {
  font-weight: 500;
  font-size: 11px;
  height: max-content;
  margin: 0;
}
.form-sec {
  height: 300px;
  background: #e0e0e0;
}
.photo-box {
  border: 1px solid black;
  height: 200px;
  width: 150px;
  overflow: hidden;
}
.text-p1 {
  color: #707f3c;
}
.button-p1 {
  background: #707f3c;
  height: 40px;
  border: #707f3c;
  border-radius: 10px;
}
.button-p1:hover {
  background: #5c6831;
}
.follow-links {
  margin-top: 20px;
  width: 200px;
  display: flex;
  justify-content: space-between;
}
.follow-links a *{
  color: #707f3c;
  height: 30px;
  width: 30px;
}
.follow-links a:hover *{
  color: #9cb052;
}
.top-padding {
  padding-top: 50px;
}
.image-choose {
  position: absolute;
  right: 0;
}
  .table-wrap {
    max-width: 100wv;
    overflow-x: scroll;
  }
.form-preview-format input {
  background-color: white;
  outline: 0;
  border: none;
  color: black;
  font-weight: 500;
}

/* loader */
.loader {
  box-sizing: border-box;
  position: relative;
  width: 48px;
  height: 48px;
  animation: spin 1s linear infinite;
}
.loader:after, .loader:before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 50%;
  background: #FF3D00;
  animation: spin 1s linear infinite;
  transform-origin: 0px 100%;
}
.loader:before {
  transform-origin: 0 50%;
  background: #fff;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.loader-div {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.388);
  z-index: 10;
}
.blur {
  filter: blur(8px);
}
.bg-successful {
  background: #707f3c;
}